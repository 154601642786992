import { isUuid } from "~/lib/regex";
import { useCheckout } from "./extensions/useCheckout";

export const useInitialShippingState = async () => {
	const route = useRoute();
	const { query } = route;
	const { pickup } = query as { pickup: string | null };

	if (!pickup) {
		return;
	}

	const { setShippingMethod } = useSessionContext();
	if (pickup === "delivery") {
		await setShippingMethod({ id: useCheckout().SHIPPING_METHODS.delivery.id });
		return;
	}

	// set shipping method to pickup
	await setShippingMethod({ id: useCheckout().SHIPPING_METHODS.pickup.id });

	if (!isUuid(pickup)) {
		return;
	}

	// set pickupLocationId to cart extension
	const { updateExtension } = useCart();
	await updateExtension({
		pickupLocationId: pickup,
		deliveryTimeSlot: null
	});
};
